import React from 'react';
import styled from 'styled-components';

import AspectRatioContainer from './AspectRatioContainer';
import WPImage from './WPImage';

const DateComp = styled.div`
  width: 100%;
  margin: 1rem 0 0.5rem 0;
  font-family: ffvGaramond, serif;
  font-size: 1rem;
  color: white;
  text-align: left;
`;

const Wrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 2rem;
  width: 80%;
  @media all and (max-width: 700px) {
    width: 100%;
  }
`;

const Headline = styled.div`
  font-family: ffvCorporateFat;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: white;
  text-align: left;
  margin-top: 32px;
`;

const BodyText = styled.div`
  font-family: ffvCorporateFat;
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: white;
`;

export default ({ big, image, postDate, headline, text }) => (
  <Wrapper>
    <AspectRatioContainer aspectRatio={1}>
      <WPImage cover src={image} />
    </AspectRatioContainer>
    {/*<DateComp>{postDate}</DateComp>*/}
    <Headline dangerouslySetInnerHTML={{ __html: headline }} />
    <BodyText dangerouslySetInnerHTML={{ __html: text }} />
  </Wrapper>
);
