import React from 'react';

import { Grid } from '@material-ui/core';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import DiaryPost from '../DiaryPost';
import Layout from '../Layout';
import PageTitle from '../PageTitle';
import Spacer from '../Spacer';

const getDays = postDate => {
  const now = new Date();
  const then = Date.parse(postDate);
  const elapsedMillis = now - then;
  return Math.floor(elapsedMillis / 1000 / 3600 / 24);
};

const renderDiary = posts => {
  posts.sort((a, b) => {
    return Date.parse(b.date) - Date.parse(a.date);
  });
  return (
    <Grid container spacing={40} justify="center">
      {posts.map((post, i) => {
        if (i === 0) {
          return (
            <Grid key={post.id} item xs={12} sm={9}>
              <DiaryPost
                big={useMediaQuery('(min-width: 600px)')}
                image={post.acf.image.url}
                postDate={`${getDays(post.date)} days ago`}
                text={post.acf.text}
                headline={post.title.rendered}
              />
            </Grid>
          );
        }

        return (
          <Grid key={post.id} item xs={12} sm={5}>
            <DiaryPost
              image={post.acf.image.url}
              postDate={`${getDays(post.date)} days ago`}
              text={post.acf.text}
              headline={post.title.rendered}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ({ pageContext: { searchIndex, diary } }) => {
  return (
    <>
      <Layout background={true} searchIndex={searchIndex}>
        <PageTitle>Diary</PageTitle>
        <Spacer space="3rem" />
        {renderDiary(diary)}
      </Layout>
    </>
  );
};
